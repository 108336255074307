import React, { useEffect, useState } from 'react';
import { CheckCircle, PendingActions, Person } from '@mui/icons-material';
import '../styles/ApprovalChain.scss';
import ApprovalChainLink from './ApprovalChainLink';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import useApprovalChain from './utils';
import service from 'util/apiService';
import { Portal } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import mockResponse from './api';


interface ApprovalChainProps {
  totalApprovals: number;
  baseUrl: string;
  flowId: string;
}

const ApprovalChain: React.FC<ApprovalChainProps> = ({ flowId, baseUrl }) => {

  const [approvalChainData, setApprovalChainData] = React.useState<any>({});
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true);
    // service.get(`${baseUrl}/approvals/chain/flow/09def4b3-0d56-49b3-bc03-433bd7675baf/task/675814afc77e98aa07dbcba5`).then(({ data }) => {
    service.get(`${baseUrl}/approvals/chain/flow/${flowId}`).then(({ data }) => {
      setApprovalChainData(data);
    }).finally(()=>{
      setLoading(false);
    })
  }, [])

  console.log(approvalChainData, "approvalChainData")
  const  { groupedApprovers, mergedGroups, completedApprovalGroupCount, hasAnyRejectedApprovers } = useApprovalChain(approvalChainData);

  console.log(groupedApprovers, mergedGroups, completedApprovalGroupCount, "groupedApprovers")


  return (
    <div className="approvals-chain-container">
      <div className="title-wrap">
        <div className="lv-set">
          <div className="label">Approvals</div>
          {!hasAnyRejectedApprovers ? <div className="value">{completedApprovalGroupCount} out of {mergedGroups.length}</div> : <div className="value">Rejected</div>}
        </div>
      </div>
      <div className="approvals-wrap">
        {
          mergedGroups.map((group)=>{
            return <ApprovalChainLink
            key={group.role}
            approversByRole={group.approversByRole}
            groupInfo={group}
          />
          })
        }        
    
      </div>
      <Portal container={document.body}>          
        <Backdrop open={loading} className="main-backdrop-custom">
          <CircularProgress className="main-loader-custom" />
        </Backdrop>
      </Portal>
    </div>
  );
};

export default ApprovalChain;
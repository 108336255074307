import React, { useContext, useEffect, useRef } from 'react';
import { Typography, Box, Button, Chip, Grid, IconButton, Portal, Backdrop, CircularProgress, useMediaQuery, type Theme, Tab, Tabs, Drawer } from '@mui/material';
import { type ApprovalContextProps, ApprovalDetails as ApprovalDetailsType, type ApprovalObject, ApprovalTypes, type ReturnUseApprovalsProps } from 'modules/cbre-approvals-widget/types';
import { Divider } from '@mui/material';
import '../styles/ApprovalDetails.scss';
import ApprovalActions from './approvalActions';
import ApprovalChainHistoryContainer from 'modules/cbre-approvals-widget/components/ApprovalChainHistoryContainer';
import { useApprovalsContext } from 'modules/cbre-approvals-widget/components/approvalContext';
import RequestDetailsViewDrawer from './requestDetails';
import ApprovalDetailsView from './ApprovalDetails';
import { useLocation, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ApprovalChain } from 'modules/cbre-approval-chain-widget';
import { ApprovalsHistory } from 'modules/cbre-approval-history';
import { approvalTypes, renderFlowStatus } from 'modules/cbre-approvals-widget/components/utils';


function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1, }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const TabContainer: React.FC<any> = ({ approvalInfo, baseUrl, selectedApproval }) => {
    const [value, setValue] = React.useState(0);
    console.log(approvalInfo, "approvalIndo")
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <div className="approval-details-bar">
            <Tabs value={value} onChange={handleChange} aria-label="approval tabs" TabIndicatorProps={{
                sx: {
                    // display: 'none', // Hide the default indicator
                },                
            }}
            style={{
                borderBottom: "1px solid #DFE6E5",
                marginLeft: "-18px",
                marginRight: "-18px",
                paddingLeft: "18px",
                paddingRight: "18px",
                minHeight: 40
            }}
            >
                <Tab className='approval-details-container-tab-item' label="Overview" />
                <Tab className='approval-details-container-tab-item' label="Approval Chain" />
                <Tab className='approval-details-container-tab-item' label="Approval History" />
            </Tabs>
            <TabPanel value={value} index={0} className="approval-preview-card-details">
                <ApprovalDetailsView apiData={approvalInfo} mode="large" />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ApprovalChain flowId={selectedApproval?.approvalFlow?.approvalFlowId} totalApprovals={12} baseUrl={baseUrl} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ApprovalsHistory approvals={approvalInfo} flowId={selectedApproval?.approvalFlow?.approvalFlowId} baseUrl={baseUrl}/>
            </TabPanel>
        </div>
    );
};

interface ApprovalDetailsContainerProps {
    approval: ApprovalObject;
    onClose: () => void;
}

const ApprovalDetailsContainer: React.FC<ApprovalDetailsContainerProps> = ({ approval, onClose }) => {

    const { config, tabValue } = useApprovalsContext() as ApprovalContextProps;

    const [approvalResponsePreview, setApprovalResponsePreview] = React.useState({});

    const [approvalResponseLarge, setApprovalResponseLarge] = React.useState({});

    const container = useRef(null)

    const { baseUrl, approvalDetails } = config;

    const { loading, approvalDetail: approvalInfo, approvalDetailLarge } = approvalDetails;

    const [data, setData] = React.useState<ApprovalDetailsType | null>(null);

    const [requestDetailsOpen, setRequestDetailsOpen] = React.useState(false);

    const location = useLocation();

    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const requestId = searchParams.get('requestId');

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    console.log(config, approvalInfo, approvalDetails, approval, "approvaldetailsconfig")

    useEffect(() => {
        approvalDetails.getApprovalDetailsconfig({ taskId: undefined, flowId: approval.approvalFlow?.approvalFlowId, configName: "APPROVAL_MINI" }).then((response) => {
            console.log(response)
            setApprovalResponsePreview(response)
        });
        // approvalDetails.getApprovalDetails(approval.approvalTaskId, approval.approvalFlow?.approvalFlowId, false);
    }, [approval.approvalFlow?.approvalFlowId])



    const handleRequestDetails = () => {
        setRequestDetailsOpen(true);
        // Remove the query string without reloading the page
        const newUrl = location.pathname;
        // history.replace(newUrl);
        // approvalDetails.getApprovalDetails(approval.approvalTaskId, approval.approvalFlow?.approvalFlowId, true);
        approvalDetails.getApprovalDetailsconfig({ taskId: undefined, flowId: approval.approvalFlow?.approvalFlowId, configName: "APPROVAL_LARGE" }).then((response) => {
            console.log(response)
            setApprovalResponseLarge(response)
        });
    }

    useEffect(() => {
        if (requestId) {
            handleRequestDetails()
        }
    }, [requestId, location.pathname, history]);

    const renderHeaderActions = () => {

        const isContractType = approval.approvalFlowStatus?.type === "contract"


        return (
            <Box className="action-buttons">
                <Button
                    variant={"outlined"}
                    onClick={() => { handleRequestDetails() }}
                    sx={{
                        color: 'var(--primary-green-primary-main, #003f2d)',
                        borderColor: 'var(--primary-green-primary-main, #003f2d)',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 63, 45, 0.05)',
                            margin: '0 16px',
                        },
                    }}
                >
                    {isContractType ? 'See contract details' : 'View request'}
                </Button>
                {tabValue.value === "myApprovals" && <React.Fragment >
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                            backgroundColor: 'rgba(0, 63, 45, 0.15)',
                            margin: '0 16px',
                        }}
                    />
                    <ApprovalActions flowId={approval.approvalFlow?.approvalFlowId} taskId={approval.approvalTaskId} baseUrl={baseUrl} />

                </React.Fragment>}
            </Box>
        );
    }

    const approvalResponse = requestDetailsOpen ? approvalResponseLarge : approvalResponsePreview;

    const details = (approvalResponse as any)?.cdfDetails || {}
    const additionalDetails = details?.fieldSchema ? details.fieldSchema[0] || {} : {}
    const heading = approval.approvalFlow.sourceDetails?.heading;

    const handleApprovalTypes = (): keyof ApprovalTypes => {

        // if(tabValue.value === "externalApprovals") return "pendingApproval"
        const status = (approvalInfo as any)?.externalsystemapprovalstatus;
        if (status === "Approval Requested") return "pendingApproval";
        if (status === "Approval Complete") return "approved";
        if (status === "Not Needed") return "rejected";

        if (status) return status;

        return "pendingApproval"

    }

    const currentApprovalType = renderFlowStatus(approval?.approvalFlowStatus?.uniqueCode);

    return (
        <Box className="approval-details" ref={container}>
            {
                !!additionalDetails?.sectionDetails?.length && <>
                    <Box className="approval-detail-header">

                        <Box className="d-flex align-items-baseline" sx={{ gap: 2 }}>
                            {isMobile && <IconButton onClick={onClose} className='approval-detail-back-btn'>
                                <ArrowBackIcon />
                            </IconButton>}
                            <Typography variant="h5">{heading}</Typography>
                            {currentApprovalType && <div className='d-flex align-items-center'>
                                {approvalTypes[currentApprovalType].icon}
                                {<Typography variant="body2" className='ml-1'>{approvalTypes[currentApprovalType].label}</Typography>}
                            </div>}
                            {!currentApprovalType && approval?.approvalFlowStatus?.uniqueCode && <div className='d-flex align-items-center'>
                                {<Typography variant="body2" className='ml-1'>{approval?.approvalFlowStatus?.uniqueCode}</Typography>}
                            </div>}
                        </Box>

                        <Box className="action-buttons">
                            {renderHeaderActions()}
                        </Box>
                    </Box>
                    <Grid container gap={2}>
                        {/* <Grid sm={7.5}>
                            <ApprovalDetailsView approvalDetails={approvalInfo} mode="mini" />
                        </Grid>
                        <Grid sm={4}><ApprovalChainHistoryContainer approvalChain={approval} approvals={12} totalApprovals={34} /> </Grid> */}
                        <TabContainer approvalInfo={approvalResponse} baseUrl={baseUrl} selectedApproval={approval} />
                    </Grid>
                </>
            }
            <Drawer anchor="right" open={requestDetailsOpen} onClose={() => setRequestDetailsOpen(false)} ModalProps={{ keepMounted: false }} style={{ zIndex: 1250 }} sx={{ width: "100%" }} PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: '1000px',
                    // backgroundColor:  isDesktop ? '#f9f9f9': "inherit",
                    // padding: 0,
                    // margin: "15px",
                },
            }} >
                <RequestDetailsViewDrawer setIsOpen={setRequestDetailsOpen} requestId={requestId} apiData={approvalResponse} isMyApproval={tabValue.value === "myApprovals"} baseUrl={baseUrl} selectedApproval={approval} />
            </Drawer>

            <Portal container={container.current}>
                <Backdrop open={loading} className="main-backdrop-custom">
                    <CircularProgress className="main-loader-custom" />
                </Backdrop>
            </Portal>
        </Box>
    );
};

export default ApprovalDetailsContainer;
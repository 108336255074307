import React, { useState, useEffect, useRef } from 'react';
import { Box, Drawer, type Theme, Typography, useMediaQuery } from '@mui/material';
import ApprovalTabs from './ApprovalTabs';
import { type Approval, ApprovalDashboardProps, ApprovalDetails as ApprovalDetailsType, type ApprovalObject, IApprovalTab } from '../types';
import { ReactComponent as FileSvg } from '../images/files.svg';
import '../styles/ApprovalDashboard.scss';
import { useDispatch } from 'react-redux';
import { ApprovalContext } from './approvalContext';
import { tabs } from './utils';
import { ApprovalDetailsContainer } from 'modules/cbre-approval-details-widget';
import ApprovalList from 'modules/cbre-approvals-list/components/ApprovalList';
import useApprovalListener from './useApprovalListener';

const ApprovalDashboard: React.FC<ApprovalDashboardProps> = ({ components = {}, config }) => {



  const [tabValue, setTabValue] = useState<IApprovalTab["tab"]>(tabs[0]);
  const [selectedApproval, setSelectedApproval] = useState<ApprovalObject | null>(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const render = useRef({ isInitial: true }).current;
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const { approvalsCount, approvalsList, } = config;
  const { storedValue: approval_decision_changes } = useApprovalListener("approval_decision_changes")
  const previousApprovalDescision = useRef({approval_decision_changes}).current;
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isLaptop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    // if(render.isInitial){
    //   render.isInitial = false;
    //   return;
    // }
    approvalsList.setQueryParams((prev: any) => ({ ...prev, search: '', pageNumber: 1, order: approvalsList.initialQueryParams.order || 'asc' }));
    approvalsList.setApprovalMethod(tabValue.value)
    approvalsList.handlePendingChanges().then((config) => {
      config.fetchApprovals(tabValue.value);
    });
    approvalsCount.fetchApprovalsCount();
    setSelectedApproval(null);
    setDetailsOpen(false);
  }, [tabValue, page, approval_decision_changes]);

  const handleTabChange = (newValue: IApprovalTab["tab"]) => {
    setTabValue(newValue);
    setPage(0);
  };

  const handleApprovalClick = async (approval: ApprovalObject) => {
    // const details = await fetchApprovalDetails(approval.id);
    setSelectedApproval(approval);
    setDetailsOpen(true);
  };

  const handleLoadMore = (count: number) => {
    // const moreApprovals = await fetchMoreApprovals();
    //     onLoadMore(moreApprovals);
    const { params: { pageSize, pageNumber }, totalPageCount } = config.approvalsList
    const newPageNumber = Math.ceil(count! / pageSize!);
    if (pageNumber == newPageNumber + 1) return;
    if (newPageNumber! + 1 > totalPageCount) return;
    config.approvalsList.setQueryParams((prev) => {
      return { ...prev, pageNumber: Number(newPageNumber || 0) + 1 }
    }
    );
    config.approvalsList.handlePendingChanges().then((config) => {
      config.fetchApprovals(tabValue.value, true);
    });
    // setPage((prevPage) => prevPage + 1);
  };

  const contextProvider = { config, tabValue }

  const handleClose = () => {
    setDetailsOpen(false);
    setSelectedApproval(null);
  }

  const { approvals } = config.approvalsList;   

  useEffect(() => {
      if(approval_decision_changes !== previousApprovalDescision.approval_decision_changes && approvals.length > 0){
          previousApprovalDescision.approval_decision_changes = approval_decision_changes;
          handleApprovalClick(approvals[0]);
      }
      }, [approvals]);

  return (
    <ApprovalContext.Provider value={contextProvider}>
      <Box className="approval-dashboard">
        <header className="dashboard-header">
          {isDesktop && <Typography variant="h4">Approvals</Typography>}
          <ApprovalTabs value={tabValue.value} onChange={handleTabChange} tabs={tabs} />
        </header>
        <main className="dashboard-content">
          <ApprovalList
            onApprovalClick={handleApprovalClick}
            onLoadMore={handleLoadMore}
            selectedApproval={selectedApproval}
            components={components}
            config={config}
          />

          <Box className="approval-details-section">
            {selectedApproval ? isLaptop ? <ApprovalDetailsContainer approval={selectedApproval} key={selectedApproval.approvalTaskId} onClose={handleClose} />  : <Drawer anchor="right" open={detailsOpen} onClose={handleClose} sx={{ width: "100%" }} PaperProps={{
              sx: {
                width: '100%',
              },
            }}> <ApprovalDetailsContainer approval={selectedApproval} key={selectedApproval.approvalTaskId} onClose={handleClose} /> </Drawer> : (
              <div className={`no-approvals d-flex justify-content-center align-items-center flex-column h-100`} >
                <FileSvg />
              </div>
            )}

          </Box>

        </main>
      </Box>
    </ApprovalContext.Provider>

  );
};

export default ApprovalDashboard;
import React, { useState } from 'react';

import { Typography, Box, Button, Chip, Grid, IconButton, Portal, Alert, Snackbar, Backdrop, CircularProgress } from '@mui/material';
import { Divider } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import "../styles/ApprovalAction.scss";
import ActionDrawer from './actionDrawer';
import { type IApprovalActions } from 'modules/cbre-approvals-widget/types';
import service from 'util/apiService';
import ApprovalWidgetAlerts from 'modules/cbre-approvals-widget/components/approvalAlert';
import { useApprovalsContext } from 'modules/cbre-approvals-widget/components/approvalContext';
import useApprovalListener from 'modules/cbre-approvals-widget/components/useApprovalListener';

type ApprovalActionsProps = {
    // Define the props for your component here
    flowId: string;
    taskId: string;
    baseUrl: string;
};

type ActionsProps = "Approve" | "Reject" | "";

const ApprovalActions: React.FC<ApprovalActionsProps> = ({ flowId, taskId, baseUrl }) => {
    // Implement your component logic here

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [defaultAction, setDefaultAction] = useState<IApprovalActions>();
    const [alertState, setAlertState] = useState<string>('');
    const [loading, setLoading] = useState(false);

    const { setValue: updateApprovalDecision } = useApprovalListener("approval_decision_changes");
    const toggleDrawer = (open: boolean, defaultAction: ActionsProps) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setIsOpenDrawer(open);
        setDefaultAction(defaultAction)
    };

    const handleAlertClose = () => {
        setAlertMessage("");
        setAlertState("");
    }


    const handleApprove = (payload: any) => {
        return service.put(`${baseUrl}/approvals/approved/flow/${flowId}/task/${taskId}`, {comment: payload?.comment})
    }

    const handleReject = (payload: any) => {
        return service.put(`${baseUrl}/approvals/rejected/flow/${flowId}/task/${taskId}`, {comment: payload?.comment})
    }

    const handleSubmit = async (action: IApprovalActions, comment: string) => {
        console.log('Action:', action);
        console.log('Comment:', comment);
        setLoading(true);

        try{
            const handleAction = action === "Approve" ? handleApprove : handleReject;
            // throw new Error()
            const { data } = await handleAction({action, comment});

            setIsOpenDrawer(false);
            setLoading(false)
            setAlertState('success');
            setAlertMessage(`Action: ${action} submitted successfully`);
            setTimeout(()=> {
                updateApprovalDecision(Date.now().toString());
            }, 2000);
           

        }catch{
            setIsOpenDrawer(false);
            setLoading(false)
            setAlertState('failure');
            setAlertMessage(`Something went wrong`);
        }
    }

    return (
        // JSX code for your component's UI goes here
        <div>
            {flowId && taskId && <Box className="approval-action-buttons">

                <React.Fragment >
                    <Button
                        variant={"outlined"}
                        onClick={toggleDrawer(true, "Approve")}
                        startIcon={<CheckCircleOutlineIcon style={{ color: "#50AF78" }} />}
                        sx={{
                            color: 'var(--primary-green-primary-main, #003f2d)',
                            borderColor: 'var(--primary-green-primary-main, #003f2d)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 63, 45, 0.05)',
                                margin: '0 16px',
                            },
                        }}
                    >
                        Approve
                    </Button>
                    <Button
                        variant={"outlined"}
                        onClick={toggleDrawer(true, "Reject")}
                        startIcon={<CancelOutlinedIcon style={{ color: "#A03530" }} />}
                        sx={{
                            color: 'var(--primary-green-primary-main, #003f2d)',
                            borderColor: 'var(--primary-green-primary-main, #003f2d)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 63, 45, 0.05)',
                                margin: '0 16px',
                            },
                        }}
                    >
                        Reject
                    </Button>
                </React.Fragment>
            </Box>}
            <Portal container={document.body}>
                <ActionDrawer  open={isOpenDrawer} 
                toggleDrawer={toggleDrawer} setIsOpen={setIsOpenDrawer} defaultAction={defaultAction}
                handleAction={handleSubmit}
                 />
                <ApprovalWidgetAlerts message={alertMessage} alertState={alertState} handleRequestClose={handleAlertClose} />
                <Backdrop open={loading} className="main-backdrop-custom">
                    <CircularProgress className="main-loader-custom" />
                </Backdrop>
            </Portal>
        </div>
    );
};

export default ApprovalActions;
import moment from 'moment';
import { type Approver } from '../types';
import mockResponse from './api';

export default function useApprovalChain(data: any = {}) {
  const autoApprovalData = (date:string) => {
    const approver = {
      approvalFlowId: '07bc4126-bc16-47a2-a34b-fc2ad8e4e3e4',
      approvalFlowTokenId: 'e51c2f3e-b1d4-4213-a3e6-b4e9dcb42531',
      approvalFlowChainId: '2c4ec52d-3477-45a2-99cf-f5683292df85',
      approvalFlowApproversId: '2528fde5-931f-42ad-9efe-4fc9ccedbc3e',
      approverEmail: '',
      personId: 'a6c48088-26aa-4431-914f-8554018ee401',
      personType: null,
      group: 1,
      sequence: 1,
      approverRole: {
        approverRoleId: '01943d14-0436-7075-babe-b2b5c6d24517',
        role: 'TM Auto Approver',
        group: '2',
      },
      authScopeId: '32daa3a9-0ce2-4051-b1a1-0d14fad85623',
      when: 'AFTER_PREVIOUS',
      responseCount: 1,
      approvalRule: 'Must approve',
      mandatory: null,
      decision: null,
      personLimit: null,
      approver: {
        firstName: 'TM',
        lastName: 'Auto Approver',
        email: '',
        uuid: 'cdae0d00-b94e-5d47-8e37-f1bd2de45b3e',
      },
      tenantCode: null,
      clientCode: null,
      appCode: null,
      enabled: true,
      createdBy: null,
      updatedBy: null,
      createdAt: '2025-02-13T05:49:00.446Z',
      task: {},
      isActiveTask: false,
      iconClass: 'flow_approved',
      status: `Approved on ${moment(date).format('MM/DD/YYYY h:mm A')}`,
    };
    const data = {
      group: 1,
      approvers: [{ ...approver }],
      approversByRole: [
        {
          role: 'TM Auto Approver',
          approvers: [{ ...approver }],
          isActiveTask: false,
          isItHasMinApproval: false,
        },
      ],
      role: 'TM Auto Approver',
      when: 'AFTER_PREVIOUS',
      responseCount: 1,
      containerClass: 'completed',
      isItHasMinApproval: false,
    };
    return { approver, data };
  };

  const createBorderClass = (data: any, responseCount: number) => {
    if (data.isActive) return 'active';
    if (data.responseCount >= responseCount) return 'completed';
    return 'in-queue';
  };

  const renderStatus = (status: string, date: string) => {
    if (!status || status === '') return 'in-queue';
    if (status === 'FLOW_PENDING') return 'Waiting for approval';
    if (status === 'FLOW_SKIPPED') return 'No action taken';
    if (status === 'FLOW_NO_ACTION_NEEDED') return 'No action needed';
    if (status === 'FLOW_APPROVED')
      return `Approved on ${moment(date).format('MM/DD/YYYY h:mm A')}`;
    if (status === 'FLOW_REJECTED')
      return `Rejected on ${moment(date).format('MM/DD/YYYY h:mm A')}`;
  };

  const groupApprovers = (approvers: any[]) => {
    const grouped = approvers.reduce(
      (acc, approver) => {
        const { group } = approver;
        if (!acc[group]) {
          acc[group] = [];
        }
        let iconClass = '';
        const statusUniqueCode = approver?.task?.approvalFlowStatus?.uniqueCode;
        if (!approver?.task?.approvalFlowStatus?.uniqueCode)
          iconClass = 'in-queue';
        else if (
          approver?.task?.approvalFlowStatus?.uniqueCode === 'FLOW_PENDING' &&
          approver.isActiveTask
        )
          iconClass =
            approver?.task?.approvalFlowStatus?.uniqueCode?.toLowerCase();
        else if (
          approver?.task?.approvalFlowStatus?.uniqueCode &&
          !approver.isActiveTask
        )
          iconClass =
            approver.task?.approvalFlowStatus?.uniqueCode?.toLowerCase();

        acc[group].push({
          ...approver,
          iconClass,
          status: renderStatus(statusUniqueCode, approver?.task?.updatedAt),
          user: approver.task?.delegatedUser?.firstName ? approver.task.delegatedUser : approver.approver
        });
        return acc;
      },
      {} as Record<number, Approver[]>,
    );

    // Convert the grouped object to an array and sort each group by sequence
    return Object.keys(grouped).map((group) => {
      const approvers = grouped[Number(group)].sort(
        (a: any, b: any) => a.sequence - b.sequence,
      );

      // Group approvers by role within each group
      const approversByRole = approvers.reduce(
        (roleAcc: any, approver: any) => {
          const role = approver?.approverRole?.role;
          if (!roleAcc[role]) {
            roleAcc[role] = [];
          }
          roleAcc[role].push(approver);
          return roleAcc;
        },
        {} as Record<string, Approver[]>,
      );

      // Convert the approversByRole object to an array
      const approversByRoleArray = Object.keys(approversByRole).map((role) => ({
        role,
        approvers: approversByRole[role],
        isActiveTask: approversByRole[role].some(
          (approver: any) => approver?.isActiveTask,
        ),
        isItHasMinApproval:
          approversByRole[role]?.filter(
            (approver: any) =>
              approver?.task?.approvalFlowStatus?.uniqueCode ===
              'FLOW_APPROVED',
          ).length >= approversByRole[role].responseCount,
      }));

      // Calculate the containerClass value
      const approvedCount = approvers.filter(
        (approver: any) =>
          approver?.task?.approvalFlowStatus?.uniqueCode === 'FLOW_APPROVED',
      ).length;

      return {
        group: Number(group),
        approvers,
        approversByRole: approversByRoleArray,
        role: approvers[0]?.approverRole?.role,
        when: approvers[0]?.when,
        responseCount: approvers[0]?.responseCount,
        containerClass: createBorderClass(approvers[0], approvedCount),
        isItHasMinApproval: approvedCount >= approvers[0]?.responseCount,
      };
    });
  };

  const flowChain =
    data?.flowData?.approvalFlowToken?.[0]?.approvalFlowChain || {};
  const isAutoApproval =
    data?.flowData?.approvalFlowStatus?.uniqueCode === 'FLOW_APPROVED' &&
    flowChain.chainDefinitionId === null &&
    flowChain.approvalFlowApprovers?.length === 0 &&
    flowChain.approvalFlowStatus?.status === 'PASSED';

  if (isAutoApproval) {
    const ApprovalData = autoApprovalData(flowChain.approvalFlowStatus?.statusDate);
    return {
      groupApprovers:[ ApprovalData.data],
      mergedGroups: [ApprovalData.data],
      completedApprovalGroupCount: 1,
      hasAnyRejectedApprovers: false,
    };
  }

  const groupedApprovers = groupApprovers(
    flowChain.approvalFlowApprovers || [],
  );

  // Create a new grouping based on the `when` property
  const mergedGroups = groupedApprovers.reduce(
    (acc, currentGroup, index) => {
      if (currentGroup.when === 'WITH_PREVIOUS' && acc.length > 0) {
        // Merge with the previous group
        const previousGroup = acc[acc.length - 1];
        previousGroup.approvers = previousGroup.approvers.concat(
          currentGroup.approvers,
        );
        previousGroup.approversByRole = previousGroup.approversByRole.concat(
          currentGroup.approversByRole,
        );
        previousGroup.isItHasMinApproval = previousGroup.approversByRole.every(
          (r) => r.isItHasMinApproval,
        );
      } else {
        // Add as a new group
        acc.push(currentGroup);
      }
      return acc;
    },
    [] as typeof groupedApprovers,
  );

  // find group has any rejected approver

  let hasAnyRejectedApprovers = false;

  let completedApprovalGroupCount = 0;

  let isActiveGroupFounded = false;

  // Create border class for the merged group

  mergedGroups.forEach((group) => {
    if (!hasAnyRejectedApprovers)
      hasAnyRejectedApprovers = group.approvers.find(
        (approver: any) =>
          approver?.task?.approvalFlowStatus?.uniqueCode === 'FLOW_REJECTED',
      )
        ? true
        : false;
    const isActiveGroup = group.approversByRole.find(
      (role) => role.isActiveTask,
    );
    if (isActiveGroup) isActiveGroupFounded = true;

    if (!isActiveGroupFounded) completedApprovalGroupCount++;

    const isCompletedGroup = group.isItHasMinApproval;
    if (isActiveGroup) group.containerClass = 'active';
    else if (isCompletedGroup) group.containerClass = 'completed';
    else if (hasAnyRejectedApprovers) group.containerClass = 'completed';
    else group.containerClass = 'in-queue';
  });

  return {
    groupedApprovers,
    mergedGroups,
    completedApprovalGroupCount,
    hasAnyRejectedApprovers,
  };
}

import React, { useEffect, useState } from 'react';
import { Drawer, Button, Select, MenuItem, TextField, FormControl, InputLabel, Box, type Theme, useMediaQuery } from '@mui/material';
import { type SelectChangeEvent } from '@mui/material';
import { type IApprovalActions } from 'modules/cbre-approvals-widget/types';

type ActionDrawerProps = {
    open: boolean
    toggleDrawer: (open: boolean, defaultAction: IApprovalActions) => (event: React.KeyboardEvent | React.MouseEvent) => void
    setIsOpen: (open: boolean) => void
    defaultAction?: IApprovalActions
    handleAction: (action: IApprovalActions, comment: string) => void
};

const ActionDrawer: React.FC<ActionDrawerProps> = ({ open, toggleDrawer, setIsOpen, defaultAction = "", handleAction }) => {

    const isBelowTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const [action, setAction] = useState<IApprovalActions>(defaultAction);
    const [comment, setComment] = useState('');

    useEffect(() => {
        setAction(defaultAction);
        setComment("")
    }, [defaultAction]);

    const handleActionChange = (event: SelectChangeEvent<IApprovalActions>) => {
        setAction(event.target.value as IApprovalActions);
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };

    return (
        <div>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false, "")} ModalProps={{keepMounted: false}} style={{ zIndex: 1250 }}
            PaperProps={{
                sx:{
                    maxWidth: isBelowTablet ? "100%" : 440,
                    width: "100%"
                }
            }}
            >
                <Box
                    
                    sx={{ padding: 2, width: "100%", display: 'flex', flexDirection: 'column', height: '100%' }}
                    role="presentation"
                >
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="action-label">Action</InputLabel>
                        <Select
                            labelId="action-label"
                            value={action}
                            onChange={handleActionChange}
                            label="Action"
                        >
                            <MenuItem value="Approve">Approve</MenuItem>
                            <MenuItem value="Reject">Reject</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Comments (Optional)"
                        multiline
                        rows={4}
                        value={comment}
                        onChange={handleCommentChange}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <div className='d-flex justify-content-end pt-3' style={{borderTop: "1px solid #003F2D26", marginInline: -16, paddingInline: 16}}>
                        <Button variant="outlined" color="primary" onClick={toggleDrawer(false, "")}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={()=> handleAction(action, comment)}>
                            Submit
                        </Button>
                    </div>

                </Box>
            </Drawer>
        </div>
    );
};

export default ActionDrawer;
import { useState, useEffect } from 'react';

const useApprovalListener = (key: string) => {
  const [storedValue, setStoredValue] = useState<string | null>(() => {
    return sessionStorage.getItem(key);
  });

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.storageArea === sessionStorage && event.key === key) {
        setStoredValue(event.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key]);

  const setValue = (value: string) => {
    sessionStorage.setItem(key, value);
    setStoredValue(value);
    window.dispatchEvent(new StorageEvent('storage', { key, newValue: value, storageArea: sessionStorage }));
  };

  const removeValue = () => {
    sessionStorage.removeItem(key);
    setStoredValue(null);
    window.dispatchEvent(new StorageEvent('storage', { key, newValue: null, storageArea: sessionStorage }));
  };

  return { storedValue, setValue, removeValue };
};

export default useApprovalListener;